import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1000,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function PrivacyPolicyPage() {
  return (
    <>
      <Helmet>
        <title> Privacy Policy | UUPSKY Dashboard </title>
      </Helmet>

      <Container>
        <StyledContent sx={{}}>
          <Typography variant="h3" paragraph>
            Privacy Policy
          </Typography>

          <p>
            Protection of your Personal Data is important to us. This Personal Data Protection
            Policy (“Policy”) outlines how we manage the Personal Data we hold. The Policy applies
            when using Uupsky Foundation’s website (https://uupsky.com).
          </p>


          <p>
            We respect the confidentiality of Personal Data and privacy of individuals and are
            committed to complying with the Singapore Personal Data Protection Act (Act 26 of
            2012) (“PDPA”) and other applicable data protection laws, including the European
            Union (“EU”) General Data Protection Regulation (“GDPR”) where applicable. Please
            read this Policy so that you know and understand the purposes for which we collect,
            use and disclose your Personal Data.
          </p>

          <p>
            This Policy supplements but does not supersede nor replace any other consents you
            may have previously provided to us in respect of your Personal Data, and your consents
            herein are additional to any rights which any member of the Uupsky Group may have at
            law to collect, use or disclose your Personal Data. This Policy does not affect any rights
            which we may have at law in connection with the collection, use or disclosure of your
            Personal Data.
          </p>
          <p>We manage the Personal Data we hold. The Policy applies
            when using Uupsky Foundation’s website (https://uupsky.com).
            We respect the confidentiality of Personal Data and privacy of individuals and are
            committed to complying with the Singapore Personal Data Protection Act (Act 26 of
            2012) (“PDPA”) and other applicable data protection laws, including the European
            Union (“EU”) General Data Protection Regulation (“GDPR”) where applicable. Please
            read this Policy so that you know and understand the purposes for which we collect,
            use and disclose your Personal Data.</p>

          <p>This Policy supplements but does not supersede nor replace any other consents you
            may have previously provided to us in respect of your Personal Data, and your consents
            herein are additional to any rights which any member of the Uupsky Group may have at
            law to collect, use or disclose your Personal Data. This Policy does not affect any rights
            which we may have at law in connection with the collection, use or disclosure of your</p>

          <p>
            For the avoidance of doubt, to the maximum extent permitted under applicable law,
            nothing in this Policy establishes any joint and several liabilities on the part of the
            Uupsky Group members.
          </p>
          <Typography variant='h5'>Personal Data</Typography>
          <p>
            “Personal Data” refers to any data or information about you from which you can be
            identified either (a) from that data alone; or (b) from that data combined with other
            information. Examples of such Personal Data which you may provide us include
            (depending on the nature of your interaction with us):
            <ol>
              <li>
                email address and any other information relating to you which you have
                provided us in any form you may have submitted to us, or in other forms of
                interaction with you;
              </li>
              <li>information about your use of our websites and services, including cookies and
                IP addresses;</li>
              <li>your digital token wallet addresses (if you have our Privileged Card).</li>
            </ol>
          </p>

          <Typography variant='h5'>Collection of Personal Data</Typography>
          <p>Generally, we collect your Personal Data in the following ways:
            <ol>
              <li>when you subscribe to our emailing list;</li>
              <li>when you sign up through our Privileged Card.</li>
            </ol>
          </p>
          <Typography variant='h5'>Use and Disclosure of Personal Data</Typography>
          <p>You consent to our use and disclose your Personal Data for the following purposes: a)
            provide you with the products or services that have been requested; b) communicate
            with you and respond to your queries, requests and complaints; c) provide ongoing
            information about our products and services which may be of interest to you;
            d) handle disputes and conduct and facilitate investigations and proceedings; h)
            manage our infrastructure and facilitate business operations and comply with internal
            policies and procedures; and
            j) comply with any applicable rules, laws and regulations.</p>
          <Typography variant='h5'>Transfer of Personal Data</Typography>
          <p>Your Personal Data may be stored in external servers located overseas. In addition, as described
            above, in carrying out our business, it may be necessary to share information about you with and
            between our related corporations and third party service providers, some of which may be located in
            countries outside your country of residence. Such countries may not afford a standard of protection
            similar to those in your country of residence. However, we will take reasonable steps to ensure that
            your Personal Data transmitted outside of your country of residence is adequately protected. In
            addition, we will ensure that such transfers comply with the requirements of the applicable data
            protection laws.</p>
          <Typography variant='h5'>Retention of Personal Data</Typography>
          <p>We may retain your personal data for 7 years or at longest, 10 years. Where we no
            longer require your Personal Data for those purposes, we will cease to retain such
            Personal Data in accordance with our internal retention policy.</p>
          <Typography variant='h5'>Management and Security</Typography>
          <p>We have appointed Data Protection Officers to oversee our management of your
            Personal Data in accordance with this Policy and the applicable data protections law.
            We train our employees who handle your Personal Data to respect the confidentiality
            of your Personal Data, and we regard breaches of all applicable data protection laws
            very seriously.</p>
          <Typography variant='h5'>Cookie Policy</Typography>
          <p>
            “We use “cookies” to collect information about you and your activity across our
            Website. A cookie is a small piece of data that our Website stores on your computer,
            and accesses each time you visit so we can understand how you use our Website and
            serve you content based on preferences you have specified. If you do not wish to
            accept cookies from us, you should instruct your browser to refuse cookies from our
            Website, with the understanding that we may be unable to provide you with some of
            your desired services without them. This policy covers only the use of cookies between
            your computer and our Website; it does not cover the use of cookies by any advertisers.
            Third-Party Sites
          </p>
          <p>
            Our website may contain links to other websites operated by third parties, such as our
            business partners. We are not responsible for the privacy practices of websites
            operated by third parties that are linked to our website, unless that is a mandatory
            requirement by applicable data protection laws and regulations. We encourage you to
            learn about the privacy policies of such third parties. Once you have left our website,
            you should check the applicable privacy policy of such third parties at their respective
            websites to determine how they will handle any information they collect from you.
          </p>

          <Typography variant='h5'>Review of this Policy</Typography>
          <p>
            This Policy will be reviewed from time to time by us. We may also from time to time
            update this Policy to take account of new laws and technology, changes to our
            operations and practices and the changing business environment. If you are unsure
            whether you are reading the most current version, please contact us.
          </p>
          <p>
            In the event of any inconsistencies between the English version and other translations
            of this Policy, the English version shall prevail.
          </p>
          <Typography variant='h5'>
            CONTACT
          </Typography>
          <p>
            If you have any questions or problems regarding your use of this policy, site, or service,
            please contact the Data Protection Officers via Email below: <br />
            <ul>
              <li>E-mail : admin@uupsky.com</li>
            </ul>
          </p>
        </StyledContent >
      </Container >
    </>
  );
}
