
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  Grid,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  CardContent,
} from '@mui/material';
import { APIRequest, HandleError } from '../utils/APIUtils';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// mock
const moment = require('moment-timezone');


export default function SettingsPage() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleChangePassword = async () => {
    try {

      if (!newPassword || !currentPassword || !confirmNewPassword) {
        alert("password fields are required");
        return;
      }

      if (newPassword !== confirmNewPassword) {
        alert("password does not match");
        return;
      }

      const payload = {
        password: currentPassword,
        newPassword
      }

      const response = await APIRequest('post', process.env.REACT_APP_BASE_URL, "/admin/change-password", payload);
      alert(`password changed`);

    } catch (error) {
      alert(HandleError(error));
    }
  }

  return (
    <>
      <Helmet>
        <title> Settings | UUPSKY Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </Stack>

        {/* <Card style={{ marginBottom: '30px' }}>
          <Scrollbar>
            <CardContent style={{ marginBottom: '20px' }}>
              <Typography variant='h4' >Preferences</Typography>
              <Typography variant="body2" style={{ marginBottom: '20px' }}>Use below section to change the preference</Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Grid xs={12} md={12}>
                    <Checkbox /> Email Notification
                  </Grid>
                  <Grid xs={12} md={12}>
                    <Checkbox /> SMS Notification
                  </Grid>
                </Grid>
                <br />
                <div style={{ margin: '20px' }}>
                  <Button size="large" variant="contained" >
                    Save Changes
                  </Button>
                </div>
              </Grid>

              <br />
            </CardContent >
          </Scrollbar>
        </Card> */}

        <Card style={{ marginBottom: '30px' }}>
          <Scrollbar>
            <CardContent >
              <Typography variant='h4' >Change Password</Typography>
              <Typography variant="body2" style={{ marginBottom: '20px' }}>Use below section to change the password</Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                  <TextField value={currentPassword} type={'password'} onChange={(e) => setCurrentPassword(e.target.value)} label="Current password" />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <TextField value={newPassword} type={'password'} onChange={(e) => setNewPassword(e.target.value)} label="New password" />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <TextField value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} label="Confirm new password" />
                </Grid>
                <div style={{ margin: '20px' }}>
                  <Button size="large" variant="contained" onClick={handleChangePassword}>
                    Save Changes
                  </Button>
                </div>
              </Grid>

              <br />
            </CardContent >
          </Scrollbar>
        </Card >
      </Container >

    </>
  );
}
