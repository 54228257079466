import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { APIRequest, HandleError } from '../../../utils/APIUtils';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');


  const handleClick = async () => {
    if (!emailAddress) {
      alert('Email-address is required');
      return;
    }

    if (!password) {
      alert('Password is required');
      return;
    }

    try {
      const payload = {
        emailAddress,
        password
      }

      const response = await APIRequest('post', process.env.REACT_APP_BASE_URL, '/auth/login', payload);
      localStorage.setItem("logged-in-user", JSON.stringify(response.data.body.userDetails));
      localStorage.setItem("access-token", response.data.body.token);

      if (response.data.body.userDetails.userType !== "admin") {
        alert("Not authorised to perform this action");
        return;
      }

      navigate('/dashboard', { replace: true });
    } catch (error) {
      alert(HandleError(error));
    }

  };

  return (
    <>
      <Stack spacing={3}>
        <TextField value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} label="Email address" />

        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
