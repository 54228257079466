import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1000,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function PrivacyPolicyPage() {
  return (
    <>
      <Helmet>
        <title> Terms of Use | UUPSKY Dashboard </title>
      </Helmet>

      <Container>
        <StyledContent sx={{}}>
          <Typography variant="h3" paragraph>
            Terms of Use for UUPSKY
          </Typography>

          <Typography variant="h5">Acceptance of Terms and Conditions</Typography>
          <p>
            By using UUPSKY ("the App"), you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use the App.
          </p>
          <Typography variant="h5">Data Collection and Usage</Typography>
          <p>
            - The App collects and processes personal data, including but not limited to your name, email address, and age. This information is used solely for the purpose of providing you with scheduling and planning services.
          </p>
          <p>
            - We do not share your personal data with third parties.
          </p>

          <Typography variant="h5">Data Security</Typography>
          <p>
            We take data security seriously and have implemented industry-standard protocols to protect your personal information from unauthorized access or breaches.
          </p>
          <Typography variant="h5">User Consent</Typography>
          <p>By clicking "Agree to Terms and Conditions," you provide consent for us to collect and use your personal data as described herein.
          </p>
          <Typography variant="h5">Accoutn Registration</Typography>
          <p>Users are required to create an account to access and use the App. You are responsible for maintaining the confidentiality of your account information, including your username and password.
          </p>

          <Typography variant="h5">User Responsibilities</Typography>
          <p>Users are responsible for the content they provide on the App. Do not include content that you consider sensitive or explicit, as we cannot guarantee its security in case of a data breach.
          </p>
          <Typography variant="h5">Terminiation</Typography>
          <p>We reserve the right to terminate or suspend your account and access to the App at our discretion if you violate these terms or engage in any harmful activities within the App.
          </p>

          <Typography variant="h5">Data Retention</Typography>
          <p>If you choose to delete your account, we will retain your information for a period of six (6) months, after which it will be permanently deleted.
          </p>

          <Typography variant="h5">Privacy Policy</Typography>
          <p>This document is part of our commitment to safeguarding your privacy. For more information on how we collect, use, and protect your personal data, please refer to our Privacy Policy.
          </p>

          <Typography variant="h5">Legal Jurisdiction</Typography>
          <p>These Terms and Conditions are governed by the laws of the United Kingdom and are subject to the exclusive jurisdiction of the courts in the United Kingdom.
          </p>

          <Typography variant="h5">Legal Jurisdiction</Typography>
          <p>For questions, support, or complaints related to the App or these Terms and Conditions, please contact us at admin@uupsky.com.
          </p>

          <Typography variant="h5">GDPR Compliance</Typography>
          <p>We are committed to complying with the General Data Protection Regulation (GDPR) to protect your rights and privacy. For more details on our GDPR compliance, please refer to our Privacy Policy.
          </p>

          <br />
          <p>By using the App, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using the App.</p>

        </StyledContent >
      </Container >
    </>
  );
}
