import axios, * as others from 'axios';


export function APIRequest(requestType, baseUrl, endpoint, payload) {

    const config = {
        method: `${requestType}`,
        url: `${baseUrl}${endpoint}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access-token')}`,
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*'
        },
        data: payload
    }

    return axios(config)
}

export function HandleError(error) {
    let errorMessage = null;
    if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        errorMessage = `${error.response.data.message}`;
    }
    else if (error.request) {
        console.log(error.request);
        errorMessage = error.request;
    } else {
        console.log(error);
        errorMessage = `Ohh, something went wrong`;
    }
    return errorMessage;
}