import { Navigate, useRoutes } from 'react-router-dom';
import jwtDecode from "jwt-decode";
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import TermsPage from './pages/TermsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SettingsPage from './pages/SettingsPage';
import UserDetailsPage from './pages/UserDetailsPage';

function RequireAuth({ children, redirectTo }) {
  const token = localStorage.getItem('access-token');
  if (!token) { return <Navigate to={redirectTo} replace='true' /> }

  let isAuthenticated = false;
  const decode = jwtDecode(token);

  // JWT exp is in seconds
  if (decode == null || decode.exp * 1000 < new Date().getTime()) {
    isAuthenticated = false;
    localStorage.removeItem('access-token');
  } else {
    isAuthenticated = true;
  }
  return isAuthenticated ? children : <Navigate to={redirectTo} replace='true' />;
}



export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <RequireAuth children={<DashboardAppPage />} redirectTo={'/login'} /> },
        { path: 'users', element: <RequireAuth children={<UserPage />} redirectTo={'/login'} /> },
        { path: 'users/view/:identifier', element: <RequireAuth children={<UserDetailsPage />} redirectTo={'/login'} /> },
        { path: 'settings', element: <RequireAuth children={<SettingsPage />} redirectTo={'/login'} /> },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: 'terms-and-conditions', element: <TermsPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
