import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  CardContent,
} from '@mui/material';
// components
import Scrollbar from '../components/scrollbar';
// sections
import { APIRequest, HandleError } from '../utils/APIUtils';

// mock
const moment = require('moment-timezone');


export default function UserDetailsPage() {
  const navigate = useNavigate();
  const { identifier } = useParams();
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const response = await APIRequest('get', process.env.REACT_APP_BASE_URL, `/admin/get-user?identifier=${identifier}`, null);
      setUserDetails(response.data.body);

    } catch (error) {
      alert(HandleError(error));
    }
  }

  const handlePasswordReset = async () => {
    try {
      const response = await APIRequest('post', process.env.REACT_APP_BASE_URL, `/admin/reset-user-password`, { identifier });
      alert(`Password has been reset and shared to user`);

    } catch (error) {
      alert(HandleError(error));
    }
  }

  const handleDelete = async () => {
    try {
      const response = await APIRequest('delete', process.env.REACT_APP_BASE_URL, `/admin/delete-user`, { identifier });
      alert(`user has been deleted`);

    } catch (error) {
      alert(HandleError(error));
    }
  }

  const handleUserEnableOrDisable = async () => {
    try {
      const isAlreadyEnabled = userDetails.status === "active";
      let endpoint = `/admin/enable-user`;

      if (isAlreadyEnabled) {
        endpoint = `/admin/disable-user`;
      }

      const response = await APIRequest('post', process.env.REACT_APP_BASE_URL, endpoint, { identifier });
      alert(`user access updated`);

    } catch (error) {
      alert(HandleError(error));
    }
  }

  return (
    <>
      <Helmet>
        <title> User Details | UUPSKY Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User Details
          </Typography>
        </Stack>

        <Card style={{ marginBottom: '30px' }}>
          <Scrollbar>
            <CardContent style={{ marginBottom: '20px' }}>
              <Typography variant='h5' style={{ fontWeight: '200' }} >{userDetails.identifier}</Typography>
              {/* <Typography variant="body2" style={{ marginBottom: '20px' }}>Use below section to change the preference</Typography> */}
              <br />

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant='body1' >Username: {userDetails.username}</Typography>
                  <Typography variant='body1' >Full Name: {userDetails.name}</Typography>
                  <Typography variant='body1' >Email-address: {userDetails.emailAddress}</Typography>
                  <Typography variant='body1' >Age: {userDetails.age || 'NA'} </Typography>
                  <Typography variant='body1' >Gender: {userDetails.gender || 'NA'}</Typography>
                  <Typography variant='body1' >Country: {userDetails.country || 'NA'}</Typography>

                  <Typography variant='body1' >Status: {userDetails.status}</Typography>
                  <br />
                  <br />
                  <Typography variant='h6' >Audit Logs</Typography>
                  <Typography variant='body1' >Last Updated On: {userDetails.updatedOn}</Typography>
                  <Typography variant='body1' >Created On: {userDetails.createdOn}</Typography>
                  <br />
                  <br />
                </Grid>
                <Grid item xs={12} md={6} >
                  <Button onClick={handleUserEnableOrDisable} size="large" variant="contained" color={'primary'} style={{ float: 'right', marginRight: '20px' }} >
                    {userDetails.status !== "active" ? "Enable User" : "Disable User"}
                  </Button>

                </Grid>
              </Grid>

              <Grid container spacing={3}>

                <div style={{ margin: '20px' }}>
                  <Typography variant='h6' >Account access management</Typography><br />
                  <Button onClick={handlePasswordReset} size="large" variant="contained" color={'error'} style={{ float: 'right', marginRight: '20px' }} >
                    Reset Password
                  </Button>
                  <Button onClick={handleDelete} size="large" variant="contained" color={'error'} style={{ float: 'right', marginRight: '20px' }} >
                    Delete User
                  </Button>
                </div>
              </Grid>

              <br />
            </CardContent >
          </Scrollbar>
        </Card>
      </Container >


    </>
  );
}
